import React, { useState, useEffect } from "react";
import "./styles.scss";

function AuditViewMenuItem({
    etape,
    setEtape,
    setLastEtape,
    menuEtape,
    etapeMenuTitle,
    isEtapeValid,
    isEtapeSeen,
}) {
    const [isPopupActive, setIsPopupActive] = useState(true);

    useEffect(() => {
        setIsPopupActive(true);
    }, [etape, setIsPopupActive]);

    // @Todo ajout des états error et validé avec les classes is-error et is-valid ainsi que la div "error" pour les erreurs
    let activeClass = "";

    if (isEtapeSeen) {
        if (isEtapeValid) {
            activeClass = " is-valid";
        } else {
            activeClass = " is-error";
        }
    }

    return (
        <div
            className={`audit-menu-item${
                etape === menuEtape ? " is-active" : ""
            }${activeClass || ""}`}
            onClick={() =>
                setEtape((oldEtape) => {
                    setLastEtape(oldEtape);
                    return menuEtape;
                })
            }>
            {etapeMenuTitle}

            {isPopupActive && (
                <div className="error" onClick={() => setIsPopupActive(false)}>
                    <span className="icon-close" />
                    Attention, il semblerait que certaines informations
                    n&apos;aient pas été renseignées
                </div>
            )}
        </div>
    );
}

export default AuditViewMenuItem;
