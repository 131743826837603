import React, { useState, useEffect } from "react";
import "./styles.scss";

function AuditsAttendu({
    title,
    details,
    justificatifs,
    informations,
    selectedAudit,
    niveauLabellisation,
}) {
    const [isActive, setIsActive] = useState(false);

    // Au changement de l'audit visionné, on reset le state des attendus
    useEffect(() => {
        setIsActive(false);
    }, [selectedAudit]);

    return (
        <div
            className={`attendus-dropdown${
                isActive ? " is-active" : ""
            } ${niveauLabellisation}`}>
            <div
                className="attendus-dropdown__attendu attendus-dropdown__attendu--main"
                onClick={() => setIsActive(!isActive)}>
                <span className="attendu__title">Attendus</span>
                <span className="attendu__content">{title}</span>
            </div>
            <div className="attendus-dropdown__attendu attendus-dropdown__attendu--details">
                <span className="attendu__title">Détails / Explications</span>
                <span className="attendu__content">{details}</span>
            </div>
            <div className="attendus-dropdown__attendu attendus-dropdown__attendu--justificatifs">
                <span className="attendu__title">
                    Exemples de justificatifs
                </span>
                <span className="attendu__content">{justificatifs}</span>
            </div>
            {informations && (
                <div className="attendus-dropdown__attendu attendus-dropdown__attendu--informations">
                    <span className="attendu__title">
                        Informations auditeur
                    </span>
                    <span className="attendu__content">{informations}</span>
                </div>
            )}
        </div>
    );
}

export default AuditsAttendu;
