import React, { useEffect } from "react";
import "./styles.scss";

// Components
import Overlay from "../../Overlay";

function AuditsNotes({
    notes,
    setNotes,
    isNotesActive,
    setIsNotesActive,
    audits,
    setAudits,
    selectedAudit,
}) {
    // Au update des notes, mettre à jour le state et le localStorage
    const updateAuditState = () => {
        if (audits && selectedAudit !== null) {
            // On trouve d'abord l'audit correspondant
            const correspondingAudit = audits[selectedAudit];

            if (correspondingAudit) {
                correspondingAudit.notes = notes;

                // Puis on mets à jour les audits en général
                const auditsStateCopy = [...audits];
                auditsStateCopy[selectedAudit] = correspondingAudit;

                setAudits(auditsStateCopy);
            }
        }
    };

    // Mise à jour du state lorsque les notes changent
    useEffect(() => {
        updateAuditState();
    }, [notes, setAudits]);

    return (
        <Overlay
            title="Bloc notes"
            isActive={isNotesActive}
            className="overlay-notes"
            handleOverlayClose={() => setIsNotesActive(false)}>
            {notes !== false ? (
                <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    onBlur={(e) => setNotes(e.target.value)}
                />
            ) : null}
        </Overlay>
    );
}

export default AuditsNotes;
