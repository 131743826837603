import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

function AuditLink({
    audit,
    currentAuditIndex,
    setSelectedAudit,
    setIsNotesActive,
    setNotes,
    listingState,
    disabled,
}) {
    const setActiveAudit = () => {
        setSelectedAudit(currentAuditIndex);
        setIsNotesActive(true);

        if (audit?.notes) {
            setNotes(audit.notes);
        } else {
            setNotes("");
        }
    };

    return (
        <Link
            to={`/audits/${audit.ID}`}
            className={`audit-link ${listingState || ""}`}
            disabled={disabled}>
            <div className="left-part">
                <span className="left-part__title">
                    {audit.club.post_title}
                </span>
                <span className="left-part__infos">
                    <span className={`infos__label ${audit.label_vise}`}>
                        {audit.label_vise}
                    </span>
                    <span className="icon-arrow-small-right" />
                </span>
            </div>

            <button
                className="right-part"
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    setActiveAudit();
                }}>
                <span className="icon-documents" />
            </button>
        </Link>
    );
}

export default AuditLink;
