const dataAttendus = {
    bronze: {
        subtitle:
            "Le tableau ci-dessous regroupe, pour un club, les attendus du niveau Bronze du Label Golf pour la Biodiversité. Il a donc été construit à destination des clubs avec les références et renvois au Memento Club. Il est complété par une 4e colonne, à destination des auditeurs.",
        introduction:
            "Le niveau Bronze est le niveau le plus accessible pour un club souhaitant obtenir le Label. Il est important dans ce niveau d’encourager la dynamique, de voir que le club ait un engagement sincère et de ne pas être trop strict, tout en vérifiant que les attendus sont mis en œuvre. Il sera important que le club poursuive sa lancée.",
        attendus: [
            {
                title: "Un diagnostic écologique correspondant aux attentes du niveau Bronze",
                details:
                    "Le diagnostic écologique est réalisé lors de l’étape 3 de la démarche.\n\nSon rendu à la ffgolf via la plateforme est fait lors de l’étape 4 de restitution",
                justificatifs:
                    "Le rapport d’expertise de la structure naturaliste\n\n Les relevés des données naturalistes : espèces et habitats (Excel)\n\nLes fichiers cartographiques liés au golf (Dossier Zip de fichiers SIG)",
                informations:
                    "Concentrez-vous sur les préconisations de la Structure Naturaliste, notamment lors de l’audit documentaire afin de percevoir vers quoi le club doit tendre",
            },
            {
                title: "Définition avec la structure naturaliste du plan d'action et des actions à mettre en œuvre",
                details:
                    "L’objectif est de prioriser et d’établir  dans le temps, un calendrier de mise en  œuvre des préconisations émises par  la structure naturaliste. Ce travail est  réalisé conjointement entre le club et la  structure naturaliste lors de la réunion de  présentation des résultats et de définition  du plan d’action.",
                justificatifs:
                    "Plan d’action simple (forme libre) reprenant  a minima :\n\nLes actions pour prétendre à la candidature  au Label Golf pour la Biodiversité (cf. étape 5),  et d’autres, après l’obtention de la labellisa tion (étape 9) \n\nLes référents internes en charge du suivi et  de la coordination des actions \n\nLe calendrier prévisionnel de mise en œuvre",
                informations:
                    "Comprendre le choix des actions à mettre en place définies avec la structure naturaliste. \n\nVoir comment le club s’en empare, le fait vivre, va au-delà, apporte ses idées et personnalise sa démarche",
            },
            {
                title: "Nommer un référent biodiversité dans le club",
                details:
                    "Salarié (ou bénévole – élu) référent du club sur les actions liées à la biodiversité",
                justificatifs:
                    "Transmission du contact du référent lors de la candidature au Label",
                informations:
                    "Comprendre son rôle, ses missions, ses interactions avec l’équipe, les partenaires etc..",
            },
            {
                title: "Mise en œuvre de 2 actions préconisées et définies avec la structure naturaliste",
                details:
                    "Ces deux actions, définies en concertation avec la structure naturaliste lors de la création du plan d’action, seront à réaliser avant de candidater au Label. Ces actions sont personnalisées en fonction du club, de son contexte géographique et du diagnostic écologique réalisé",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (champ texte dans la plateforme) \n\nTout élément prouvant sa réalisation sur le terrain : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Demander des explications de mises en œuvre, des documents justificatifs si besoin, les choix opérés, les résultats obtenus, les  ",
            },
            {
                title: "Mise en œuvre d’une action de sensibilisation à la biodiversité (ponctuelle ou pérenne) vers les pratiquants",
                details:
                    "Les modalités de sensibilisation et le type d’action mis en place, sont laissés à l’appréciation du club. L’objectif est la prise de conscience des golfeurs du lieu où ils jouent\n\nExemples : Panneaux de communication au départ ou sur le parcours, visites guidées sur le golf avec des naturalistes, compétition en faveur de la biodiversité, journée portes ouvertes biodiversité, conférence d’un expert naturalist ",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (comment, avec qui, pourquoi ce format...) et estimation du nombre de personnes sensibilisées (champ texte dans la plateforme) \n\nTout élément pouvant montrer la réalisation : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Comprendre les choix opérés, la mise en œuvre, les résultats, la communication, les partenaires impliqués...",
            },
            {
                title: "Suite à l’obtention du Label, mise en place de 5 actions préconisées et définies avec la structure naturaliste",
                details:
                    "Le Programme Golf pour la Biodiversité est une démarche d’amélioration continue ambitieuse \n\nAprès l’obtention du Label, continuez à agir pour préserver la biodiversité de votre site \n\nEn vous appuyant sur votre plan d’action défini à l’étape 4, réalisez les 5 actions définies «à mettre en place sur les 5 ans qui suivent l’obtention de la Labellisation»",
                justificatifs:
                    "Justificatifs demandés lors du réengagement dans la démarche",
                informations:
                    "L’avoir en tête et le rappeler au club. Voir comment il va s’organiser, le timing, les évolutions \n\nVérifier que les 5 actions à mettre en place se retrouvent dans le plan d’action \n\nLors d’un audit de renouvellement de labellisation ou de candidature à un niveau supérieur, l’auditeur vérifiera cet attendu",
            },
        ],
    },
    argent: {
        subtitle:
            "Le tableau ci-dessous regroupe, pour un club, les attendus du <strong>niveau Argent</strong> du Label Golf pour la biodiversité. Il a donc été construit à destination des clubs avec les références et renvois au Memento Club.",
        introduction:
            "Les attendus du niveau Argent sont plus exigeants que ceux du niveau Bronze tout en restant dans la même dynamique encourageante et de progrès. Un club peut se lancer dans le Label par ce niveau sans avoir obtenu le niveau Bronze au préalable.",
        attendus: [
            {
                title: "Un diagnostic écologique correspondant aux attentes du niveau Argent",
                details:
                    "Le diagnostic écologique est réalisé lors de l’étape 3 de la démarche \n\nSon rendu à la ffgolf via la plateforme est fait lors de l'étape 4 de restitution ",
                justificatifs:
                    "Le rapport d’expertise de la structure naturaliste \n\n Les relevés des données naturalistes : espèces et habitats (Excel) \n\nLes fichiers cartographiques liés au golf (Dossier Zip de fichiers SIG)",
                informations:
                    "Concentrez-vous sur les préconisations de la Structure Naturaliste, notamment lors de l’audit documentaire afin de percevoir vers quoi le club doit tendre",
            },
            {
                title: "Définition avec la structure naturaliste du plan d’action et des actions à mettre en œuvre",
                details:
                    "L’objectif est de prioriser et d’établir dans le temps, un calendrier de mise en œuvre des préconisations émises par la structure naturaliste. \n\nCe travail est réalisé conjointement entre le club et la structure naturaliste lors de la réunion de présentation des résultats et de définition du plan d’action.",
                justificatifs:
                    "Plan d’action simple (forme libre) reprenant a minima :\n\n Les actions pour prétendre à la candidature au Label Golf pour la Biodiversité (cf. étape 5), et d’autres, après l’obtention de la labellisation (étape 9)\n\n Les référents internes en charge du suivi et de la coordination des actions\n\n Le calendrier prévisionnel de mise en œuvre",
                informations:
                    "Comprendre le choix des actions avec la structure naturaliste, comment le club s’en empare, le fait vivre, va au-delà, apporte ses idées, se personnalise sa démarche",
            },
            {
                title: "Nommer un référent biodiversité dans le club",
                details:
                    "Salarié (ou bénévole – élu) référent du club sur les actions liées à la biodiversité",
                justificatifs:
                    "Tout document ou mention justifiant l’officialisation de cette mission à un salarié ou bénévole.\n\n Si le référent est un salarié : Fiche de poste du salarié mentionnant la mission de référent biodiversité / organigramme de la structure...\n\n Si le référent est bénévole : décision du bureau / CA / réunion, mention sur le site web",
                informations:
                    "Comprendre son rôle, ses missions, ses interactions avec l’équipe, les partenaires etc..",
            },
            {
                title: "Mise en œuvre de 5 actions préconisées et définies avec la structure naturaliste",
                details:
                    "Ces 5 actions, définies en concertation avec la structure naturaliste lors de la création du plan d’action, seront à réaliser avant de candidater au Label. Ces actions sont personnalisées en fonction du club, de son contexte géographique et du diagnostic écologique réalisé",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (champ texte dans la plateforme)\n\n Tout élément prouvant sa réalisation sur le terrain : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Confronter les attendus à la réalité du terrain, demander des explications de mises en œuvre, des documents justificatifs si besoin, les choix opérés, les résultats obtenus, les difficultés",
            },
            {
                title: "Mise en œuvre d’une action de sensibilisation à la biodiversité pérenne vers les pratiquants",
                details:
                    "Les modalités de sensibilisation et le type d’action mis en place, sont laissés à l’appréciation du club. Cependant la sensibilisation doit être pérenne, c’est à dire continuellement à disposition des pratiquants ou proposée régulièrement sur l’année. L’objectif est la prise de conscience des golfeurs du lieu où ils jouent\n\nExemples : Panneaux au départ ou sur le parcours, exposition photos biodiversité, visites guidées sur le golf avec des naturalistes, module de formation-sensibilisation à la biodiversité en école de golf, journée portes ouvertes biodiversité, programme d’animation, communication engageante et innovante sur le site Web",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (comment, avec qui, pourquoi ce format...) et estimation du nombre de personnes sensibilisées (champ texte dans la plateforme)\n\n Tout élément pouvant montrer la réalisation : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Comprendre les choix opérés, la mise en œuvre, les résultats, la communication, les partenaires impliqués",
            },
            {
                title: "Sensibilisation des salariés à la biodiversité",
                details:
                    "L’objectif est de sensibiliser tous vos salariés (cela ne concerne pas uniquement les équipes terrain) afin que ceux-ci puissent : connaître vos actions / valoriser vos actions et engagements auprès des pratiquants et que chacun puisse être un ambassadeur de la démarche du club. La sensibilisation peut être coordonnée par le référent biodiversité (si besoin, en soutien avec la structure naturaliste)",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (comment, avec qui, pourquoi ce format...) et estimation du nombre de personnes sensibilisées (champ texte dans la plateforme)\n\n Tout élément pouvant montrer la réalisation : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Comprendre la mise en œuvre, la manière dont l’équipe se l’approprie, le réutilise. Possibilité d’échanger avec les membres de l’équipe. Voir si cela transparait lors des échanges avec les clients...",
            },
            {
                title: "Suite à l’obtention du Label, mise en place de 8 actions préconisées et définies avec la structure naturaliste",
                details:
                    "Le Programme Golf pour la Biodiversité est une démarche d’amélioration continue ambitieuse. Après l’obtention du Label, continuez à agir pour préserver la biodiversité de votre site. En vous appuyant sur votre plan d’action défini à l’étape 4, réalisez les 8 actions définies «à mettre en place sur les 5 ans qui suivent l’obtention de la Labellisation»",
                justificatifs:
                    "Justificatifs demandés lors du réengagement dans la démarche",
                informations:
                    "L’avoir en tête et le rappeler au club. Voir comment il va s’organiser, le timing, les évolutions...\n\n Vérifier que les 8 actions à mettre en place se retrouvent dans le plan d’action\n\n Lors d’un audit de renouvellement de labellisation ou de candidature à un niveau supérieur, l’auditeur vérifiera cet attendu",
            },
        ],
    },
    or: {
        subtitle:
            "Le tableau ci-dessous regroupe, pour un club, les attendus du <strong>niveau Or</strong> du Label Golf pour la biodiversité. Il a donc été construit à destination des clubs avec les références et renvois au Memento Club.",
        introduction:
            "Le niveau Or représente l’excellence du Label et donc des clubs labélisés. Le niveau demandé est bien supérieur. Le club candidat aura au préalable été labellisé niveau Argent et est engagé dans la démarche depuis un certain temps. Une exigence dans la mise en œuvre des attendus et dans le fonctionnement de la structure vis-à-vis de la biodiversité est exigée.",
        attendus: [
            {
                title: "Un diagnostic écologique correspondant aux attentes du niveau Or",
                details:
                    "Le diagnostic écologique est réalisé lors de l’étape 3 de la démarche\n\n Son rendu à la ffgolf via la plateforme est fait lors de l’étape 4 de restitution",
                justificatifs:
                    "Le rapport d’expertise de la structure naturaliste\n\n Les relevés des données naturalistes : espèces et habitats (Excel)\n\n Les fichiers cartographiques liés au golf (Dossier Zip de fichiers SIG)",
                informations:
                    "Concentrez-vous sur les préconisations de la Structure Naturaliste, notamment lors de l’audit documentaire afin de percevoir vers quoi le club doit tendre\n\n Regarder également l’évolution de la biodiversité avec les éléments du diagnostic écologique lors de la labellisation\n\n Argent : vérifier si les préconisations soulevées au niveau Argent ont été mises en place",
            },
            {
                title: "Définition avec la structure naturaliste du plan d’action et des actions à mettre en œuvre",
                details:
                    "L’objectif est de prioriser et d’établir dans le temps, un calendrier de mise en œuvre des préconisations émises par la structure naturaliste. Ce travail est réalisé conjointement entre le club et la structure naturaliste lors de la réunion de présentation des résultats et de définition du plan d’action",
                justificatifs:
                    "Plan d’action simple (forme libre) reprenant a minima :\n\n Les actions pour prétendre à la candidature au Label Golf pour la Biodiversité (cf. étape 5), et d’autres,après l’obtention de la labellisation (étape 9)\n\n Les référents internes en charge du suivi et de la coordination des actions\n\n Le calendrier prévisionnel de mise en œuvre",
                informations:
                    "Comprendre le choix des actions avec la structure naturaliste, comment le club s’en empare, le fait vivre, va au-delà, apporte ses idées, personnalise sa démarche",
            },
            {
                title: "Confirmer le référent biodiversité dans le club",
                details:
                    "Salarié référent du club sur les actions liées à la biodiversité",
                justificatifs:
                    "Fiche de poste du référent biodiversité : doit apparaître la mission du référent biodiversité dans la fiche de poste",
                informations:
                    "Comprendre son rôle, ses missions, ses interactions avec l’équipe, les partenaires. Sentir l’importance de sa mission vis-à-vis du niveau Or visé",
            },
            {
                title: "Mise en œuvre de toutes les actions préconisées par la structure naturaliste",
                details:
                    "Ces actions, définies en concertation avec la structure naturaliste lors de la création du plan d’action, seront à réaliser avant de candidater au Label. Ces actions sont personnalisées en fonction du club, de son contexte géographique et du diagnostic écologique réalisé",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (champ texte dans la plateforme)\n\n Tout élément prouvant sa réalisation sur le terrain : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Confronter les attendus à la réalité du terrain sur le terrain, demander des explications de mises en œuvre, des documents justificatifs si besoin, les choix opérés, les résultats obtenus, les difficultés. Le club a déjà via le niveau argent mis en place de nombreuses actions",
            },
            {
                title: "Mise en œuvre d’une action de sensibilisation ponctuelle et annuelle à la biodiversité vers les pratiquants",
                details:
                    "Le club met en place une animation ponctuelle mais qui doit être reconduite annuellement. Les modalités de sensibilisation et le type d’action mis en place, sont laissés à l’appréciation du club. L’objectif est la prise de conscience des golfeurs du lieu où ils jouent\n\n Exemples : journée portes ouvertes biodiversité, visites guidées sur le golf avec des naturalistes, compétition en faveur de la biodiversité club (lots en lien avec la biodiversité ou contribution financière des participants afin de financer la création d’une mare sur le parcours), conférence d’un expert naturaliste",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (comment, avec qui, pourquoi ce format...) et estimation du nombre de personnes sensibilisées (champ texte dans la plateforme)\n\n Tout élément pouvant montrer la réalisation : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Comprendre les choix opérés, la mise en œuvre, les résultats, la communication, les partenaires impliqués, la récurrence...",
            },
            {
                title: "Mise en œuvre d’une action de sensibilisation à la biodiversité pérenne vers les pratiquants",
                details:
                    "Les modalités de sensibilisation et le type d’action mis en place, sont laissés à l’appréciation du club. Cependant la sensibilisation doit être pérenne, c’est à dire continuellement à disposition des pratiquants ou proposée régulièrement sur l’année. L’objectif est la prise de conscience des golfeurs du lieu où ils jouent\n\n Exemples : Panneaux de communication, exposition photos biodiversité, visites guidées avec des naturalistes échelonnées sur l’année, module de formation-sensibilisation à la biodiversité en école de golf, programme d’animation autour de la biodiversité, communication engageante sur le site Web",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (comment, avec qui, pourquoi ce format...) et estimation du nombre de personnes sensibilisées (champ texte dans la plateforme)\n\n Tout élément pouvant montrer la réalisation :photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Comprendre les choix opérés, la mise en œuvre, les résultats, la communication, les partenaires impliqués, le caractère pérenne...",
            },
            {
                title: "Sensibilisation des salariés à la biodiversité",
                details:
                    "L’objectif est de sensibiliser tous vos salariés (cela ne concerne pas uniquement les équipes terrain) afin que ceux-ci puissent : connaître vos actions / valoriser vos actions et engagements auprès des pratiquants et que chacun puisse être un ambassadeur de la démarche du club. La sensibilisation peut être coordonnée par le référent biodiversité (si besoin, en soutien avec la structure naturaliste)",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (comment, avec qui, pourquoi ce format...) et estimation du nombre de personnes sensibilisées (champ texte dans la plateforme)\n\n Tout élément pouvant montrer la réalisation : photos, vidéos, factures, explication de la réalisation, article explicatif... (bac de dépôt – Plateforme)",
                informations:
                    "Comprendre la mise en œuvre, la manière dont l’équipe se l’approprie, le réutilise. Possibilité d’échanger avec les membres de l’équipe. Voir si cela transparait lors des échanges avec les clients...",
            },
            {
                title: "Mise en place d’une action en faveur de la biodiversité en collaboration avec les acteurs du territoire",
                details:
                    "L’objectif est d’ouvrir le golf aux acteurs du territoire, de partager et valoriser les actions mises en place.\n\nExemples :\nAccueil d’écoliers du territoire pour une sensibilisation à la biodiversité. \nImpulsion par le golf pour la mise en place d’un atlas de la biodiversité communale (en lien avec sa commune). \nAccueil d’une espèce sur le golf, dans le cadre d’une démarche de compensation écologique par exemple. \nProjet en partenariat avec l’ARB (Agence Régionale de la Biodiversité), la communauté de communes, l’ONF (Office National des Forêts), etc.",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (champ texte dans la plateforme)\n\nTout élément pouvant montrer la réalisation (bac de dépôt – Plateforme)",
            },
            {
                title: "Implication dans un Programme d’expérimentation / R&D validé avec la ffgolf",
                details:
                    "Le Programme Golf pour la Biodiversité s’inscrit dans un partenariat ffgolf – MNHN qui est un Programme National d’Études de la biodiversité des golfs français. \n\nL’objectif est donc d’engager le golf dans cette philosophie et de développer les études scientifiques autour de la biodiversité dans le golf. \n\nExemples : \nAccueil d’un étudiant chercheur développant des actions innovantes en faveur de la biodiversité. \nProjet international : partage / échanges avec un golf partenaire à l’étranger. \nAccueil d’un programme d’expérimentation",
                justificatifs:
                    "Explication simple de ce qui a été réalisé (champ texte dans la plateforme) Tout élément pouvant montrer la réalisation (bac de dépôt – Plateforme)",
            },
            {
                title: "Aider et accompagner d’autres clubs dans les démarches du Label",
                details:
                    "L’objectif est que le club candidatant au Label niveau Or soit un exemple, une source de motivation, mais aussi apporte une aide au club accompagné. \n\nExemples \nLe club peut : \nPartager ses conseils et bonnes pratiques. \nAider dans les étapes de la démarche (conseil d’une structure naturaliste, mise en place des actions de sensibilisation auprès de ses pratiquants,…) \nAccompagner dans la recherche de financements.",
                justificatifs:
                    "Nom du / des club(s) que vous «accompagnez» et les démarches effectuées auprès de celui-ci / ceux-ci (champ texte) \nTout élément pouvant montrer la réalisation (bac de dépôt – Plateforme)",
            },
            {
                title: "Suite à l’obtention du Label, incitation à entreprendre de nouvelles actions",
                details:
                    "Le Programme Golf pour la Biodiversité est une démarche d’amélioration continue ambitieuse. \nAprès l’obtention du Label, maintenez le niveau d’exigence du niveau Or et continuez à agir pour préserver la biodiversité de votre site.",
                justificatifs:
                    "Justificatifs demandés lors du réengagement dans la démarche",
            },
        ],
    },
};

export default dataAttendus;
