import { useState } from "react";

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem("token");

        if (tokenString) {
            const userToken = JSON.parse(tokenString);
            return userToken?.token;
        }

        return false;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        if (userToken && userToken.token) {
            localStorage.setItem("token", JSON.stringify(userToken));
            setToken(userToken.token);
        }
    };

    return {
        setToken: saveToken,
        token,
    };
}
