import React from "react";
import "./styles.scss";

function AuditViewEtapeAncres({ fields, refs }) {
    const handleClickAnchor = (e, ref) => {
        e.preventDefault();

        // Récupération de la taille du header
        const headerSize = document.querySelector("#header").clientHeight;

        // Scroll to
        window.scrollTo({
            top: ref.current.offsetTop - (headerSize + 20),
            behavior: "smooth",
        });
    };

    return (
        <nav className="audit-etape__ancres">
            <ul>
                {fields.length > 1 &&
                    fields.map(
                        (field, index) =>
                            refs[`menuancre${index}`] && (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={index} className="is-active">
                                    <a
                                        href={`#menuancre${index}`}
                                        onClick={(e) =>
                                            handleClickAnchor(
                                                e,
                                                refs[`menuancre${index}`]
                                            )
                                        }>
                                        {field.title
                                            ? field.title
                                            : `Champ ${index + 1}`}
                                    </a>
                                </li>
                            )
                    )}
            </ul>
        </nav>
    );
}

export default AuditViewEtapeAncres;
