import React from "react";
import "./styles.scss";

function LoginResponse({ text, isError }) {
    return (
        <div className={`login-response ${isError ? "is-error" : null}`}>
            {text}
        </div>
    );
}

export default LoginResponse;
