import React, { useState } from "react";
import "./styles.scss";

// Components
import AuditLink from "../Link";

function AuditsListing({
    user,
    audits,
    setSelectedAudit,
    setIsNotesActive,
    setNotes,
}) {
    const [hasAuditsNotSeen, setHasAuditsNotSeen] = useState(false);
    const [hasAudits, setHasAudits] = useState(false);
    const [hasAuditsArchive, sethasAuditsArchive] = useState(false);

    audits.forEach((audit) => {
        if (!audit.is_seen && !hasAuditsNotSeen) {
            setHasAuditsNotSeen(true);
        } else if (
            audit.post_status === "publish" &&
            audit.is_seen &&
            audit.etape < 11 &&
            !hasAudits
        ) {
            setHasAudits(true);
        } else if (
            (audit.post_status === "archive" || audit.etape >= 11) &&
            !hasAuditsArchive
        ) {
            sethasAuditsArchive(true);
        }
    });

    return (
        <section className="audits-homepage">
            <section className="audits-homepage__wrapper">
                <div className="audits-homepage__header">
                    <h1>
                        Outil d’audit de
                        <br />
                        <strong>
                            {user.fields.auditeur_prenom}{" "}
                            {user.fields.auditeur_nom}
                        </strong>
                    </h1>
                </div>

                {audits ? (
                    <div className="audits-homepage__listing">
                        <div className="listing-audits">
                            <span className="listing-audits__title">
                                Nouvelles demandes d’audits
                            </span>
                            {hasAuditsNotSeen ? (
                                <>
                                    {audits.map((audit, index) => {
                                        if (!audit.is_seen) {
                                            return (
                                                <AuditLink
                                                    key={audit.ID}
                                                    audit={audit}
                                                    currentAuditIndex={index}
                                                    setSelectedAudit={
                                                        setSelectedAudit
                                                    }
                                                    setIsNotesActive={
                                                        setIsNotesActive
                                                    }
                                                    setNotes={setNotes}
                                                    listingState="audits-not-seen"
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </>
                            ) : (
                                <p>Aucun nouveau dossier de golf.</p>
                            )}
                        </div>
                        <div className="listing-audits">
                            <span className="listing-audits__title">
                                Audits en cours
                            </span>
                            {hasAudits ? (
                                <>
                                    {audits.map((audit, index) => {
                                        if (
                                            audit.is_seen &&
                                            audit.post_status === "publish" &&
                                            audit.etape < 11
                                        ) {
                                            return (
                                                <AuditLink
                                                    key={audit.ID}
                                                    audit={audit}
                                                    currentAuditIndex={index}
                                                    setSelectedAudit={
                                                        setSelectedAudit
                                                    }
                                                    setIsNotesActive={
                                                        setIsNotesActive
                                                    }
                                                    setNotes={setNotes}
                                                    listingState="audits-en-cours"
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </>
                            ) : (
                                <p>Aucun audit en cours.</p>
                            )}
                        </div>
                        <div className="listing-audits">
                            <span className="listing-audits__title">
                                Audits archivés
                            </span>
                            {hasAuditsArchive ? (
                                <>
                                    {audits.map((audit, index) => {
                                        if (
                                            audit.post_status === "archive" ||
                                            audit.etape >= 11
                                        ) {
                                            return (
                                                <AuditLink
                                                    key={audit.ID}
                                                    audit={audit}
                                                    currentAuditIndex={index}
                                                    setSelectedAudit={
                                                        setSelectedAudit
                                                    }
                                                    setIsNotesActive={
                                                        setIsNotesActive
                                                    }
                                                    setNotes={setNotes}
                                                    disabled
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </>
                            ) : (
                                <p>Aucun audit archivé.</p>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Aucun audit en attente.</p>
                )}
            </section>
        </section>
    );
}

export default AuditsListing;
