import { useState, useEffect } from "react";

function useDetectOnline() {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        setIsOnline(window.navigator.onLine);

        window.addEventListener("online", () => {
            setIsOnline(window.navigator.onLine);
        });

        window.addEventListener("offline", () => {
            setIsOnline(window.navigator.onLine);
        });
    }, []);

    return { isOnline };
}

export default useDetectOnline;
