import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

// Components
import Login from "../Login";
import AuditsListing from "../Audits/Listing";

function Dashboard() {
    const {
        user,
        setSelectedAudit,
        audits,
        isNotesActive,
        setIsNotesActive,
        setNotes,
        token,
        loadingUsers,
        loadingAudits,
    } = useOutletContext();
    useEffect(() => {
        setSelectedAudit(null);
    }, [setSelectedAudit]);

    let content = null;

    if (token && !loadingUsers && !loadingAudits) {
        if (user && audits) {
            content = (
                <AuditsListing
                    user={user}
                    audits={audits}
                    setSelectedAudit={setSelectedAudit}
                    isNotesActive={isNotesActive}
                    setIsNotesActive={setIsNotesActive}
                    setNotes={setNotes}
                />
            );
        } else {
            content = <span>loading</span>;
        }
    } else {
        content = <Login />;
    }

    return <main>{content}</main>;
}

export default Dashboard;
