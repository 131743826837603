import React from "react";
import "./styles.scss";

function HeaderButton({ text, handleClickButton, secondaryClass, iconName }) {
    // la classe "small-mobile" est là pour transformer les boutons qui l'ont en bouton rond sur tablette (à partir de 1024px)
    return (
        <button
            type="button"
            onClick={handleClickButton}
            className={`header-button small-mobile ${secondaryClass}`}>
            <span className={iconName} />
            {text}
        </button>
    );
}

export default HeaderButton;
