import React from "react";
import "./styles.scss";

function DisconnectModal({ isModalOpen, setIsModalOpen, handleDeconnexion }) {
    return (
        <div
            className={`overlay disconnect-modal${
                isModalOpen ? " is-active" : ""
            }`}>
            <div className="disconnect-modal__content">
                <span
                    className="icon-close"
                    onClick={() => setIsModalOpen(false)}
                />
                <h2>
                    Êtes-vous sûr de vouloir
                    <br /> quitter la page ?
                </h2>
                <p>
                    Les données non enregistrées
                    <br /> pourraient être perdues
                </p>
                <button
                    className="btn"
                    type="button"
                    onClick={(e) => {
                        handleDeconnexion(e);
                    }}>
                    Oui, je veux quitter la page
                </button>
                <button
                    className="btn stay"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(false);
                    }}>
                    Non, je reste sur la page
                </button>
            </div>
            <div
                className="overlay__shadow disconnect-modal__shadow"
                onClick={() => setIsModalOpen(false)}
            />
        </div>
    );
}

export default DisconnectModal;
