import React from "react";
import "./styles.scss";

function ResyncModal({ isModalOpen, setIsModalOpen, isOnline, getData }) {
    return (
        <div
            className={`overlay disconnect-modal${
                isModalOpen ? " is-active" : ""
            }`}>
            <div className="disconnect-modal__content">
                <span
                    className="icon-close"
                    onClick={() => setIsModalOpen(false)}
                />
                <h2>
                    Êtes-vous sûr de vouloir
                    <br /> resynchroniser ?
                </h2>
                <p>
                    Les données non enregistrées
                    <br /> pourraient être perdues
                </p>
                {isOnline && (
                    <button
                        className="btn"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();

                            setIsModalOpen(false);
                            getData(true);
                        }}>
                        Resynchroniser
                    </button>
                )}
                <button
                    className="btn stay"
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();

                        setIsModalOpen(false);
                    }}>
                    Revenir sur la page
                </button>
            </div>
            <div
                className="overlay__shadow disconnect-modal__shadow"
                onClick={() => setIsModalOpen(false)}
            />
        </div>
    );
}

export default ResyncModal;
