import React from "react";
import "./styles.scss";

function LoadingModal({ isActive, text }) {
    return (
        <div className={`saving-audit${isActive ? " is-active" : ""}`}>
            <div className="saving-audit__content">
                <span className="text">{text}</span>
                <div className="saving-audit__spinner">
                    <div className="lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingModal;
