import React from "react";
import "./styles.scss";

// Composants
import FooterButton from "./FooterButton";

function Footer({ setIsModalOpen }) {
    return (
        <footer>
            <FooterButton
                text="Déconnexion"
                handleClickButton={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                }}
                secondaryClass="full"
                iconName="icon-logout"
            />
        </footer>
    );
}

export default Footer;
