import React from "react";
import "./styles.scss";

function Overlay({
    title,
    titleSub,
    children,
    isActive,
    handleOverlayClose,
    className,
}) {
    return (
        <div
            className={`overlay ${className} ${isActive ? "is-active" : null}`}>
            <div className="overlay__shadow" onClick={handleOverlayClose} />
            <div className="overlay__content">
                {title ? (
                    <h3 className="overlay__title">
                        {title}
                        {titleSub ? (
                            <span className="overlay__title__hover">
                                {" "}
                                {titleSub}
                                <div className="tooltip">
                                    Le tableau ci-dessous regroupe, pour un
                                    club, les attendus du <b>{titleSub}</b> du
                                    Label Golf pour la Biodiversité. Il a donc
                                    été construit à destination des clubs avec
                                    les références et renvois au Memento Club.
                                </div>
                            </span>
                        ) : null}
                    </h3>
                ) : null}
                {children ? (
                    <div className="overlay__children">{children}</div>
                ) : null}

                <button
                    className="btn-close"
                    type="button"
                    onClick={handleOverlayClose}>
                    <span className="icon-close" />
                </button>
            </div>
        </div>
    );
}

export default Overlay;
