import React from "react";
import "./styles.scss";

// Components
import AuditViewMenuItem from "./MenuItem";

function AuditViewMenu({ etape, setEtape, setLastEtape, etapeData }) {
    return etapeData ? (
        <nav className="audit-menu">
            {etapeData.map((step) => (
                <AuditViewMenuItem
                    key={step.id}
                    etape={etape}
                    setEtape={setEtape}
                    setLastEtape={setLastEtape}
                    menuEtape={step.etape}
                    etapeMenuTitle={step?.menu_title}
                    isEtapeValid={step?.is_valid}
                    isEtapeSeen={step?.is_seen}
                />
            ))}
        </nav>
    ) : null;
}

export default AuditViewMenu;
