import React from "react";
import "./styles.scss";

function FooterButton({ text, handleClickButton }) {
    return (
        <div className="footer-zone">
            <button
                type="button"
                onClick={handleClickButton}
                className="footer-zone--button small-mobile">
                {text}
            </button>
            <p className="footer-zone--copyright">
                © 2023 - Golf pour la biodiversité
            </p>
        </div>
    );
}

export default FooterButton;
