import { useState, useEffect, useCallback } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

function useCallbackPrompt(isFormModified) {
    const blocker = useBlocker(isFormModified);
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastUrlClicked, setLastUrlClicked] = useState(null);

    const cancelShowPrompt = useCallback(() => {
        setShowPrompt(false);
    }, []);

    useEffect(() => {
        setShowPrompt(blocker?.state === "blocked");
        setLastUrlClicked(blocker?.location?.pathname);
    }, [blocker]);

    return [showPrompt, cancelShowPrompt, lastUrlClicked];
}

export default useCallbackPrompt;
