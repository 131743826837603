import React, { useRef, useEffect } from "react";
import "./styles.scss";

function AuditViewField({
    title,
    description,
    inputs,
    setInputs,
    radio,
    commentaire,
    index,
    refs,
    setRefs,
    placeholder,
    disabled,
}) {
    const ref = useRef(null);
    const refId = `menuancre${index}`;

    useEffect(() => {
        if (ref && !refs[refId]?.current) {
            setRefs((oldRefs) => ({ ...oldRefs, [refId]: ref }));
        }
    }, [ref, setRefs, refs, refId]);

    return (
        <fieldset
            className="audit-etape__fieldset"
            id={`menuancre${index}`}
            ref={ref}>
            <legend>{title}</legend>
            {description ? <p className="description">{description}</p> : null}

            {radio ? (
                <div className="audit-etape__fieldset__radio">
                    <input
                        type="radio"
                        name={radio}
                        id={`${radio}_oui`}
                        value="oui"
                        onChange={({ target }) => {
                            setInputs((state) => ({
                                ...state,
                                [radio]: target.value,
                            }));
                        }}
                        checked={inputs[radio] === "oui"}
                        disabled={disabled}
                    />
                    <label htmlFor={`${radio}_oui`}>Oui</label>

                    <input
                        type="radio"
                        name={radio}
                        id={`${radio}_oui_mais`}
                        value="oui_mais"
                        onChange={({ target }) => {
                            setInputs((state) => ({
                                ...state,
                                [radio]: target.value,
                            }));
                        }}
                        checked={inputs[radio] === "oui_mais"}
                        disabled={disabled}
                    />

                    <label htmlFor={`${radio}_oui_mais`}>Oui mais</label>

                    <input
                        type="radio"
                        name={radio}
                        id={`${radio}_non`}
                        value="non"
                        onChange={({ target }) => {
                            setInputs((state) => ({
                                ...state,
                                [radio]: target.value,
                            }));
                        }}
                        checked={inputs[radio] === "non"}
                        disabled={disabled}
                    />

                    <label htmlFor={`${radio}_non`}>Non</label>
                </div>
            ) : null}

            {commentaire && (!radio || inputs[radio]) ? (
                <div className="audit-etape__fieldset__textarea">
                    <textarea
                        name={commentaire}
                        id={commentaire}
                        cols="30"
                        rows="10"
                        onChange={({ target }) => {
                            setInputs((state) => ({
                                ...state,
                                [commentaire]: target.value,
                            }));
                        }}
                        onBlur={({ target }) => {
                            setInputs((state) => ({
                                ...state,
                                [commentaire]: target.value,
                            }));
                        }}
                        value={inputs[commentaire]}
                        placeholder={
                            placeholder ||
                            (inputs[radio] && inputs[radio] === "oui"
                                ? "Faites ressortir les points forts de l'action relatifs à l'attendu"
                                : "Justifier le ou les manques / erreurs du club relatifs à l'attendu")
                        }
                    />
                </div>
            ) : null}
        </fieldset>
    );
}

export default AuditViewField;
